export const getRoute = function (routes, routeName) {
  let result = null;

  for (let idx = 0; idx < routes.length; idx++) {
    const element = routes[idx];

    result = getChildrenRoute(routeName, element);
    if (result) return result;
  }

  return result;
};

export const getNameAliasRoute = function () {
  var alias = window.location.pathname.split('/')[1];
  if (alias.toLowerCase() == 'publico') {
    return '';
  }
  return alias;
};

const getChildrenRoute = function (routeName, route) {
  if (route.name === routeName) return route;

  if (!route.children) return null;

  let childResult = null;
  for (let index = 0; index < route.children.length; index++) {
    const child = route.children[index];

    if (child.name === routeName) return child;

    if (child.children) {
      childResult = getChildrenRoute(routeName, child);
      if (childResult) return childResult;
    }
  }

  return null;
};
